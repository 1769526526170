

import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import firebase from 'firebase/app';
import 'firebase/analytics'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCfTVq1s9-th_OGTLm_Jl-U83naYcPCTt0",
  authDomain: "ilutoradmin.firebaseapp.com",
  databaseURL: "https://ilutoradmin.firebaseio.com",
  projectId: "ilutoradmin",
  storageBucket: "ilutoradmin.appspot.com",
  messagingSenderId: "935480632894",
  appId: "1:935480632894:web:4c6d479cc03291fc9e536f",
  measurementId: "G-WHZP2NKWDH"
  // apiKey: "AIzaSyDOQSHWAotYvVUzJpTMVAO3W29ugT8GSkk",
  // authDomain: "iltutor-dev.firebaseapp.com",
  // databaseURL: "https://iltutor-dev.firebaseio.com",
  // projectId: "iltutor-dev",
  // storageBucket: "iltutor-dev.appspot.com",
  // messagingSenderId: "195823761468",
  // appId: "1:195823761468:web:9bd32ee7b98b5673ca2b39",
  // measurementId: "G-V6J31YXVD6"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

const config = {
    //siteUrl: "http://localhost:8700",
    siteUrl: "https://api.ilutor.com",

    errors: {
        generic: 'Something went wrong, please try later',
        noBalance: 'Insufficient balance',
        invalidPhone: 'Phone number is invalid',
        invalidPhoneCode: 'Code provided is invalid',

    },


}
module.exports = config;



//"http://localhost:5201/anioma-gas/us-central1",//
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/admin',
    name: 'Home',
    component: Home,
    props: true,
    children: [
      {
        path: "videos",
        name: 'Videos',
        component: () => {
          return import(/* webpackChunkName: "Videos" */ '../components/videos/videosView.vue');
        },
      },
      {
        path: "courses",
        name: 'Courses',
        component: () => {
          return import(/* webpackChunkName: "Courses" */ '../components/courses/coursesView.vue');
        },
      },
      {
        path: "topics",
        name: 'Topics',
        component: () => {
          return import(/* webpackChunkName: "Topics" */ '../components/topics/topicsView.vue');
        },
      },
      {
        path: "categories",
        name: 'Categories',
        component: () => {
          return import(/* webpackChunkName: "Categories" */ '../components/categories/categoriesView.vue');
        },
      },
      {
        path: "subscriptions",
        name: 'Subscriptions',
        component: () => {
          return import(/* webpackChunkName: "Subscriptions" */ '../components/subscription/subscriptionView.vue');
      },
    },
      {
        path: "sites",
        name: 'Sites',
        component: () => {
          return import(/* webpackChunkName: "Sites" */ '../components/sites/sitesView.vue');
        },
      },
      {
        path: "/",
        name: 'Sales',
        component: () => {
          return import(/* webpackChunkName: "Sales" */ '../components/sales/salesView.vue');
        },
      },

      {
        path: "users-panel",
        name: 'Users',
        component: () => {
          return import(/* webpackChunkName: "Users" */ '../components/users/usersView.vue');
        },
      },

    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => {
      return import(/* webpackChunkName: "login" */ '../views/login.vue');
    }
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
